import {DOM} from "./DOM";

interface NotificationText {
    success: string;
    error: string
}

export default class App {
    selectorCacheProvider: DOM.SelectorCacheProvider;

    constructor() {
        this.selectorCacheProvider = new DOM.SelectorCacheProvider();
    }

    /**
     * @param {string} text
     * @return Promise
     */
    copyText(text: string) {
        return navigator.clipboard
            .writeText(text)
            .then(() => {
                console.log('copied:', text);
            })
            .catch(reason => {
                console.error('copy error:', reason);
            });
    }

    $<E extends Element = Element>(selector: string, cache: boolean = true): NodeListOf<E> {
        return this.selectorCacheProvider.get(selector, cache);
    }
}