export interface NodeCacheElement<E extends Element = Element> extends NodeListOf<E> {
}

export interface NodeCache {
    [key: string]: NodeCacheElement
}

export class SelectorCacheProvider {
    protected cache: NodeCache;

    constructor() {
        this.cache = {};
    }

    get<E extends Element = Element>(selector: string, cache: boolean): NodeListOf<E> {
        if (!cache) {
            return document.querySelectorAll(selector);
        }

        if (typeof this.cache[selector] === 'undefined') {
            this.cache[selector] = document.querySelectorAll(selector);
        }

        return <NodeListOf<E>>this.cache[selector];
    }
}