import App from "./App";

let app = new App();

// @ts-ignore
window.app = app;
// @ts-ignore
window.productClickListener = function() {
    let title = this.querySelector('.title').innerHTML;
    let oldPrice = parseInt(this.querySelector('.price .oldPrice')?.innerText);
    let discount = parseInt(this.querySelector('.price .discount')?.innerText);
    let price = parseInt(this.querySelector('.price .price').getAttribute('data-price'));
    let server = (<HTMLElement>app.$('.server.selected a')[0])?.innerText;

    let modal: HTMLElement = <HTMLElement>app.$("#buy-modal")[0];

    if(oldPrice > 0) {
        modal.querySelector('.price .oldPrice').innerHTML = oldPrice.toFixed(0);
    }

    if(discount < 0) {
        modal.querySelector('.price .discount').innerHTML = discount.toFixed(0) + '%';
    }

    modal.querySelector('.title').innerHTML = title;
    modal.querySelector('.price .price').innerHTML = price.toFixed(0) + " руб.";
    let serverElement: HTMLElement = modal.querySelector('.serverName');
    if(serverElement) {
        serverElement.innerHTML = server;
    }

    showPopup('buy-modal');
};

// @ts-ignore
window.notification = false;
// @ts-ignore
window.notificationText = {
    success: 'ok',
    error: ':('
};

// @ts-ignore
window.categoryClickListener = function(event: Event) {
    event.preventDefault();

    let selected = app.$('.category-selector .category.selected', false);
    if(selected) {
        selected.forEach(element => {
            element.classList.remove('selected');
        });
    }

    this.classList.add('selected');
}

const showPopup = (id: string) => {
    let popupBlock = <HTMLElement>app.$(`#${id}`)[0];

    document.body.style.overflow = 'hidden';

    popupBlock.style.display = 'block';
    app.$("header, main, .footer").forEach(element => {
        element.classList.add('blurred');
    })
}

const hidePopup = (id: string) => {
    app.$('.blurred').forEach(element => {
        element.classList.remove('blurred');
    });

    document.body.style.overflow = '';

    (<HTMLElement>app.$(`#${id}`)[0]).style.display = 'none';
}

document.addEventListener('DOMContentLoaded', function() {
    app.$('input[type="range"]').forEach(el => {
        el.addEventListener("input", function(event) {
            let target: HTMLInputElement = <HTMLInputElement>event.target;

            let min: number = parseInt(target.getAttribute('min'));
            let max: number = parseInt(target.getAttribute('max'));
            let value: number|string = parseInt(target.value);

            target.style.backgroundSize = (value - min) * 100 / (max - min) + '% 100%';

            this.dispatchEvent(new Event("changeCount", {
                bubbles: false,
                cancelable: false,
                // @ts-ignore
                value: value,
            }));

            if(value > 1000000) {
                value = (value / 1000000).toFixed(2) + 'kk';
            }

            app.$(`[data-for="${el.id}"]`)[0].innerHTML = value.toString();
        });

        let event = document.createEvent('Event');
        event.initEvent('input', true, true);
        el.dispatchEvent(event);
    });

    app.$('.click2copy').forEach(element => {
        element.addEventListener('click', function() {
            app.copyText(this.getAttribute('data-value'))
                .then(() => {
                    // @ts-ignore
                    if(window.notification) {
                        const original = this.innerHTML;
                        // @ts-ignore
                        this.innerText = window.notificationText.success;

                        setTimeout(() => {
                            this.innerHTML = original;

                            // @ts-ignore
                        }, window.notificationTime || 1500);
                    }
                })
                .catch(() => {
                    // @ts-ignore
                    if(window.notification) {
                        const original = this.innerHTML;
                        // @ts-ignore
                        this.innerText = window.notificationText.error;

                        setTimeout(() => {
                            this.innerHTML = original;

                            // @ts-ignore
                        }, window.notificationTime || 1500);
                    }
                });

        });
    });

    app.$('.server-selector .server').forEach(element => {
        element.addEventListener('click', function(event) {
            event.preventDefault();

            let selected = app.$('.server-selector .server.selected', false);
            if(selected) {
                selected.forEach(element => {
                    element.classList.remove('selected');
                });
            }

            this.classList.add('selected');
        });
    });

    app.$('.category-selector .category').forEach(element => {
        // @ts-ignore
        element.addEventListener('click', window.categoryClickListener);
    });

    app.$('.popup').forEach((popup: HTMLElement) => {
        popup.querySelector('.popup-close').addEventListener('click', () => {
            hidePopup(popup.id);
        });

        // todo не работает
        popup.querySelector('.background').addEventListener('click', () => {
            hidePopup(popup.id);
        })
    });

    app.$('.product').forEach(product => {
        // @ts-ignore
        product.addEventListener('click', window.productClickListener)
    })

    app.$('.sidebar-open-btn')[0].addEventListener('click', function() {
        let currentButton = this.innerText;
        let sidebar: HTMLElement = <HTMLElement>app.$('.navbar-mobile .navbar-nav')[0]
        if(currentButton == "menu") {
            this.innerText = 'menu_open';
            sidebar.style.left = "0";
            sidebar.style.overflow = "scroll";
            document.body.style.overflow = "hidden";
        } else {
            this.innerText = 'menu';
            sidebar.style.left = "101%";
            document.body.style.overflow = "";
        }
    });
});